import type { NextPage, NextPageContext } from 'next'
import { useRouter } from 'next/router'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { TextPlugin } from 'gsap/TextPlugin'
import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Footer from '../components/footer'
import BrandedContainer from '../components/BrandedContainer'
import LanguageSelector from '../components/LanguageSelector'
import { resolveLocale } from '../components/i18n'


gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(TextPlugin)
gsap.registerPlugin(ScrollToPlugin)

const useIsomorphicLayoutEffect = typeof window !== "undefined" 
  ? useLayoutEffect 
  : useEffect;


const MauticForm = ({id}: {id: number}) => {
    const elt = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (elt.current) {
            elt.current.innerHTML = ""
            const script = document.createElement('script')
            script.type = "text/javascript";
            script.src = `https://mic.iuvia.io/form/generate.js?id=${id}`
            elt.current.appendChild(script)
        }
    }, [elt])

    return (<div ref={elt}></div>)
}

function once<T, Opts>(this: T, el: HTMLElement, event: string, fn: Function, opts?: Opts) {
    var onceFn = function (this: T, e: Event) {
        el.removeEventListener(event, onceFn);
        fn.apply(this, arguments);
    };
    el.addEventListener(event, onceFn, opts);
    return onceFn;
}

const Home: NextPage = () => {

    const router = useRouter()
    const { t, i18n } = useTranslation()

    const fullUrl = "https://" + `iuvia.io/${router.basePath}${router.locale === router.defaultLocale ? '' : router.locale}${router.asPath}`.replace(/\/+/, '/')


    const navbar = useRef<HTMLElement>(null)
    const secondSection = useRef<HTMLDivElement>(null)
    
    const unfoldVideoSection = useRef<HTMLDivElement>(null)
    const unfoldTrigger = useRef<HTMLDivElement>(null)
    const unfoldTitle = useRef<HTMLDivElement>(null)
    const unfoldPinned = useRef<HTMLDivElement>(null)
    const unfoldExplanation = useRef<HTMLDivElement>(null)
    const videoCanvas = useRef<HTMLCanvasElement>(null)
    const preorderStickyBtn = useRef<HTMLAnchorElement>(null)
    const animatedHeroMessage = useRef<HTMLDivElement>(null)
    let videoTween: GSAPAnimation

    useIsomorphicLayoutEffect(() => {
        const animations: gsap.core.Tween[] = []
        const TLs: gsap.core.Timeline[] = []

        const textTL = gsap.timeline({repeat: -1, repeatDelay: 2, repeatRefresh: false})
        TLs.push(textTL)
        const heroTexts = [
            t('landing.hero.text.1', 'email service'),
            t('landing.hero.text.2', 'contacts backup'),
            t('landing.hero.text.3', 'calendar system'),
            t('landing.hero.text.4', 'phone backup'),
            t('landing.hero.text.5', 'collaborative editor'),
            t('landing.hero.text.6', 'way of sharing documents'),
            t('landing.hero.text.7', 'password manage'),
            t('landing.hero.text.8', ''),
            t('landing.hero.text.9', ''),
        ]

        /* Pause hero text when out of viewport */
        const heroTextTrigger = ScrollTrigger.create({
            trigger: ".text-center.intro-text.text-hero",
            start: "top bottom",
            end: "bottom top",
            onToggle: self => { if (self.isActive) { textTL.play() } else { textTL.pause() } }
        })
        
        heroTexts.filter(text => text?.length > 0).map((text: string) => {
            textTL.to(animatedHeroMessage.current, { delay: 2, text: { value: text, delimiter: "", type: "diff", speed: 2 }})
        })
        
        /* Animate navbar */
        /*
        animations.push(gsap.fromTo(navbar.current, {opacity: 0}, {
            scrollTrigger: {
                trigger: secondSection.current,
                toggleActions: "play none none reverse",
                start: "top top",
            },
            opacity: 1,
        }))
        */
        
        animations.push(gsap.from('.features-list .card', {
            scrollTrigger: {
                trigger: '.features-list',
                start: "top 90%",
            },
            stagger: 0.1,
            opacity: 0,
            y: 50,
        }))
        
        animations.push(gsap.to('.hero-2 h2 .anim-hideable', {
            scrollTrigger: {
                trigger: '.hero-2',
                start: "top 20%",
                end: "bottom 60%",
                scrub: 1,
            },
            opacity: 0.4,
            ease: "linear",
        }))
        
        animations.push(gsap.from('.grid-2 .card', {
            scrollTrigger: {
                trigger: '.grid-2',
                start: "top 90%",
            },
            stagger: 0.2,
            opacity: 0,
            y: 50,
        }))
        
        /* Pause feats video when out of viewport */
        const onboardingVideoTrigger = ScrollTrigger.create({
            trigger: "section[data-section=onboarding] > video",
            start: "top bottom",
            end: "bottom top",
            onToggle: self => { if (self.isActive) { (self.trigger as HTMLVideoElement).play() } else { (self.trigger as HTMLVideoElement).pause() } }
        })
        
        animations.push(gsap.from(unfoldTitle.current, {
            scrollTrigger: {
                trigger: unfoldTitle.current,
                start: "-300px 90%",
                end: "0px 90%",
            },
            opacity: 0,
            y: 300,
            ease: "slow (0.6, 0.7, false)",
            //scale: 1.4,
        }))
        
        let unfoldTl = gsap.timeline()
        TLs.push(unfoldTl)
        let unfoldST = ScrollTrigger.create({
            trigger: unfoldPinned.current,
            start: "bottom bottom",
            end: "1600",
            scrub: 0.4,
            pin: true,
            animation: unfoldTl,
        })
        
        // videoTween = unfoldTl.fromTo(videoSource.current, {currentTime: 0}, {currentTime: videoSource.current?.duration || 4.8 })
        if (videoCanvas.current && window) {
            videoCanvas.current.width = 1920
            videoCanvas.current.height = 1080
            const ctx = videoCanvas.current.getContext("2d")
            if(ctx) ctx.imageSmoothingEnabled = false
            const cw = videoCanvas.current.width
            const ch = videoCanvas.current.height
            const drawCanvas = function (this: gsap.core.Animation) {
                const idx = Math.round(this.progress() * 119)
                const obj = images[idx]
                if (obj.dataset.ready) {
                    ctx?.clearRect(0, 0, cw, ch)
                    ctx?.drawImage(obj, 0, 0, cw, ch)
                    pendingRedraw = false
                } else {
                    pendingRedraw = true
                }
            }

            let pendingRedraw = false
            const images = Array.from(new Array(120),(val,index) => index + 1).map((idx) => {
                    const obj = new window.Image()
                    obj.src = `/images/landing/unfold/${("0000" + idx).slice(-4)}.jpg`
                    obj.onload = () => {
                        obj.dataset.ready = "1"
                        if (pendingRedraw) {
                            drawCanvas.call(videoTween)
                        }
                    }
                    return obj
            })
            videoTween = unfoldTl.fromTo(videoCanvas.current, {}, {onUpdate: drawCanvas})
        }
        unfoldTl.fromTo(unfoldExplanation.current, {opacity: 0}, {opacity: 1})
        
        animations.push(gsap.from(".price-card", {
            scrollTrigger: {
                trigger: ".price-card",
                start: "top bottom", 
                end: "top 70%",
            },
            y: 100,
            opacity: 0,
        }))
        return () => {
            for(const a of animations) {
                a.pause(0).kill()
                gsap.set(a.targets(), {clearProps: true})
            }
            onboardingVideoTrigger.kill(true, true)
            unfoldST.disable(true, false)
            for(const tl of TLs) {
                tl.pause(0).kill()
            }
            videoTween.kill()
        }
    })

    const scrollToCTA = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()
        gsap.to(window, { duration: 1, scrollTo: {y: "[data-section=cta]", offsetY: 0}, ease: "power4"})
    }

    return (
        <BrandedContainer
            title={t('landing.og.title')}
            description={t('landing.og.description')}
            >
            <div className="landing-page">
                <div className="page-wrapper">
                    <nav className="navbar" ref={navbar}>
                        <div className="container">
                            <div className="left">
                                <img alt="IUVIA" src="./images/logos/iuvia.svg" />
                            </div>
                            <div className="right">
                                <LanguageSelector t={t} i18n={i18n} />
                                <a href="https://blog.iuvia.io">{t('blog.short')}</a>
                                <a ref={preorderStickyBtn} id="preorder-sticky" className="btn btn-primary" href="#pricing" onClick={scrollToCTA}>{t('preorder.button.text')}</a>
                            </div>
                        </div>
                    </nav>
                    <section className="hero-banner" data-section="home" role="banner">
                        <div className="full-section flex-v centering" style={{margin: "auto", width: "95%"}}>
                            <div className="container">
                                <div className="intro-text">
                                        <span style={{fontSize: "1.5em"}}>{t('warning.202201.title')}</span><br />
                                        {t('warning.202201.description1')}<br />
                                        {t('warning.202201.description2')}
                                </div>
                            </div>
                            <div className="flex-34" style={{height: "80vh"}}>
                                <div className="centering intro-left-text">
                                    <h1 className="centering">
                                        {t('brand.teaser')}
                                    </h1>
                                    <div className="full-brand">
                                        <div className="centering"><img alt={t("fullBrand.alt")} src="./images/logos/iuvia_w_t.svg" /></div>
                                    </div>
                                    <a className="btn btn-primary" href="#pricing" onClick={scrollToCTA}>{t('preorder.button.text')}</a>
                                </div>
        
                                <div className="intro-right-video">
                                    <iframe src="https://www.youtube.com/embed/IOwdkJg5y8Q?rel=0&amp;modestbranding=1&amp;showinfo=0" title={t("IUVIA Spot on YouTube")} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    </section>
        
                    <section className="match-unfold-video-bg" ref={secondSection}>
                        <div className="container">
                            <h2>{t('section.forMe.title')}</h2>
                            <div className="text-center intro-text text-hero" style={{
                            }}>
                                    {/*}
                                    <p className="begin align-left">If you believe the current <em><div style={{display: "inline"}} ref={animatedHeroMessage}>cloud services</div></em></p>
                                    <p className="align-right">you use online does not <em>respect your privacy</em> enough...</p>
                                    */}
                                    <p className="begin align-left">
                                        {t('section.forMe.description.0.left', 'If you want')} <em><span style={{display: "inline"}} ref={animatedHeroMessage}>{t('landing.hero.text.0', 'a cloud platform')}</span></em>{t('section.forMe.description.0.right', '')}</p>
                                    <p className="align-right"><Trans i18nKey="section.forMe.description.1">that respects your privacy and is secure and convenient...</Trans></p>
                                    <p className="align-center" style={{paddingTop: "6pt", paddingBottom: "20pt"}}><Trans i18nKey="section.forMe.description.2">We designed IUVIA <em>for you.</em></Trans></p>
                            </div>
                            <div className="features-list">
                                <div className="card-list">
                                    {
                                        [
                                            ['images/landing/feats/calendar.svg', t('card.calendar.title'), t('card.calendar.description'), t('card.calendar.description2')],
                                            ['images/landing/feats/email.svg', t('card.email.title'), t('card.email.description'), t('card.email.description2')],
                                            ['images/landing/feats/contacts.svg', t('card.contacts.title'), t('card.contacts.description'), t('card.contacts.description2')],
                                            ['images/landing/feats/storage.svg', t('card.storage.title'), t('card.storage.description'), t('card.storage.description2')],
                                            ['images/landing/feats/collaborative.svg', t('card.collaborative.title'), t('card.collaborative.description'), t('card.collaborative.description2')],
                                            ['images/landing/feats/duck.svg', t('card.duck.title'), t('card.duck.description'), t('card.duck.description2')],
                                        ].map(([src, title, desc1, desc2], key) => {
                                            return (<div key={key} className="card">
                                                <img src={src} />
                                                <h3>{title}</h3>
                                                <p>{desc1}</p>
                                                <p>{desc2}</p>
                                            </div>)
                                        })
                                    }
                                </div>
                                <div className="middle-feat">
                                    <img src="images/landing/feats/iuvia_phone_laptop.svg" />
                                </div>
                            </div>
                        </div>
                    </section>
        
                    <section data-section="imagine" className="hero-2">
                        <div className="container">
                            <h2><Trans i18nKey="imagineYouKnew1"><span className="anim-hideable">Imagine you knew where </span><em>your data</em><span className="anim-hideable"> is, and</span></Trans> </h2>
                            <h2><Trans i18nKey="imagineYouKnew2"><span className="anim-hideable">who has access to it </span><em>without prying eyes.</em></Trans></h2>
                        </div>
                        <div className="hero-separator"></div>
                    </section>
        
                    <section data-section="main-values" className="match-unfold-video-bg" style={{overflow: "hidden"}}>
                        <div className="container">
                            <h2>{t('section.mainValues.title')}</h2>
                            <div className="intro-text">
                                <div className="text-center">
                                    <p>
                                        {t('section.mainValues.intro')}
                                    </p>
                                </div>
                            </div>
                            <div className="grid-2">
                                {
                                    [
                                        ['icon-lock', t('values.safe.title'), t('values.safe.description1'), t('values.safe.description2')],
                                        ['icon-people', t('values.users.title'), t('values.users.description1'), t('values.users.description2')],
                                        ['icon-refresh', t('values.obsolescence.title'), t('values.obsolescence.description1'), t('values.obsolescence.description2')],
                                        ['icon-settings', t('values.opensource.title'), t('values.opensource.description1'), t('values.opensource.description2')],
                                    ].map(([cls, title, desc1, desc2], key) => {
                                        return (<div className="card" key={key}>
                                            <div className="icon"><i className={cls}></i></div>
                                            <h3>{title}</h3>
                                            <div>
                                                <p>{desc1}</p>
                                                <p>{desc2}</p>
                                            </div>
                                        </div>)
                                    })
                                }
                            </div>
                        </div>
                    </section>
        
                    <section data-section="onboarding" className="match-unfold-video-bg">
                        <video muted autoPlay loop playsInline src="images/landing/onboarding.mp4"></video>
                    </section>
        
                    <section data-section="what-inside" className="unfold-video-section" ref={unfoldVideoSection}>
                        <div
                            id="unfold-trigger"
                            style={{
                                position: "relative",
                                paddingTop: "3rem",
                                marginTop: "-3rem",
                            }}
                            ref={unfoldTrigger}
                            >
                            
                            <div className="container unfold-content" style={{
                                position: "absolute",
                                top: "4em",
                                left: "50%",
                                transform: "translate(-50%, 0)",
                                zIndex: 1000,
                                background: "transparent",
                            }} ref={unfoldTitle}>
                                <h2>{t('unfold.title')}</h2>
                                <div className="intro-text text-center">
                                    <p>{t('unfold.description')}</p>
                                    <p>{t('unfold.description2')}</p>
                                </div>
                            </div>
                            <div id="unfold-explanation-trigger" style={{position: "absolute", top: "120vh"}}></div>
                            <div ref={unfoldPinned} id="unfold-pinned" className="responsive-container" style={{zIndex: 1}}>
                                <div ref={unfoldExplanation} id="unfold-explanation" className="unfold-content" style={{
                                    position: "absolute",
                                    top: "-60px",
                                    left: 0,
                                    zIndex: 1000,
                                    width: "100%",
                                    paddingTop: "80px"
                                }}>
                                    <div className="container">
                                        <h2 id="unfold-explanation-title">{t('unfold.explanation.title')}</h2>
                                    </div>
                                    <div className="responsive-container" style={{
                                        overflow: "visible"
                                    }}>
                                        <img id="unfold-explanation-schematics-notablet" className="tablet-hidden" src="images/landing/explanation.svg" style={{
                                            marginTop: "-120px"
                                        }} />
                                        <img id="unfold-explanation-schematics-tablet" className="tablet-shown" src="images/landing/explanation_mobile.svg" style={{
                                            marginTop: "-100px" /* HOTFIX: Look for the bug on Chrome/WebKit */
                                        }} />
                                    </div>
                                </div>
                                <canvas ref={videoCanvas} id="unfoldVideoSource"></canvas>
                            </div>
                        </div>
                    </section>
        
                    <section className="landing-section" data-section="pricing" id="pricing">
                        <div className="container">
                            <div className="row">
                                <div className="intro-text">
                                    <h2>{t('section.preorder.title')}</h2>
                                    <p>{t('section.preorder.description')}</p>
                                    <p>{t('section.preorder.description2')}</p>
                                </div>
        
                                <div className="intro-text">
                                    <div className="price-card">
                                        <div className="card-image">
                                            <img src="images/landing/iuvia-400px.png" />
                                            <h4>{t('section.preorder.fromXYZ')}</h4>
                                        </div>
                                        <div className="card-content">
                                            <h3>{t('section.preorder.card.title')}</h3>
                                            <ul>
                                                <li><Trans i18nKey="section.preorder.card.item1">1x IUVIA R1 <em>Limited Founders-Edition</em> Device</Trans></li>
                                                <li><Trans i18nKey="section.preorder.card.item2">1x year access to IUVIA Subscription Services</Trans></li>
                                                <li><Trans i18nKey="section.preorder.card.item3">Unlimited Software Updates</Trans></li>
                                                <li><Trans i18nKey="section.preorder.card.item4">Future access to the IUVIA Cloud App Store</Trans></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container mobile-fullwidth">
                            <div style={{
                                color: "black!important"
                            }}>
                                <div className="cta">
                                    <div data-section="cta" style={{
                                        position: "absolute",
                                        transform: "translateY(-30vh)",
                                    }}></div>
                                    <h2>{t('section.cta.title')}</h2>
                                    <p>{t('section.cta.description')}</p>
                                    <p><Trans i18nKey="section.cta.description2">You can join our waitlist to be emailed before we go live, so that you <strong>don't miss your chance at a reduced price.</strong></Trans></p>
                                    <div className="cta-content">
                                        <MauticForm id={6} />
                                        <img className="mascot" src="images/iuvita/iuvita-contact.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
        
                    <div className="faq-bg">
                    <section data-section="faq">
                        <div className="container dark">
                            <div className="row row-flex">
                                <div className="col-md-8 col-md-offset-2 text-center">
                                    <h2 className="">{t('section.faq.title')}</h2>
                                </div>
                            </div>
                            <div className="row row-flex">
                                <div className="col-md-12 fh5co-text value-accordion">
                                    {
                                        [
                                            [t('faq.0.title'), t('faq.0.description')],
                                            [t('faq.1.title'), t('faq.1.description')],
                                            [t('faq.2.title'), t('faq.2.description')],
                                            [t('faq.3.title'), t('faq.3.description')],
                                            [t('faq.4.title'), t('faq.4.description')],
                                            [t('faq.5.title'), t('faq.5.description')],
                                            [t('faq.6.title'), t('faq.6.description')],
                                            [t('faq.7.title'), t('faq.7.description')],
                                            [t('faq.8.title'), t('faq.8.description')],
                                        ].map(([title, desc], key) => {
                                            return (<div className="value-item" key={key}>
                                                <input type="radio" name="faq-value" id={`faq-value-${key}`} />
                                                <label htmlFor={`faq-value-${key}`}>
                                                    <h3 className="value-title">{title}</h3>
                                                    <p className="value-text">{desc}</p>
                                                </label>
                                            </div>)
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="after-faq-boundary"></div>
                    </div>
                </div>
                <Footer />
            </div>
        </BrandedContainer>
    )
}

/*
export const getStaticProps = async ({ locale }: { locale: string }) => {
    if(locale === nextI18NextConfig.i18n.defaultLocale) {
        locale = nextI18NextConfig.i18n.actualDefaultLocale
    }
    return ({
        props: {
            ...await serverSideTranslations(locale, ['common'], nextI18NextConfig)
        }
    })
}
*/

export async function getServerSideProps(
    nextPageContext: NextPageContext
): Promise<{ props: { resolvedLocale: string } }> {
    const resolvedLocale = resolveLocale(nextPageContext)

    return {
        props: {
            resolvedLocale,
            ...await serverSideTranslations(resolvedLocale as string, ['common'])
        }
    }
}


export default Home
